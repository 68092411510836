'use strict';

/**
 * Holt sich per Ajax-Request die übersetzten Strings für die agegebene Domäne
 * Gibt einen Promise zurück. Der resolve-Funktion des Promise wird eine Funktion übergeben,
 * mit der Strings lokalisiert werden können.
 *
 * Beispiel:
 * localize('Bereich').then(function(__) {
 *     var text = __('Dieser Text wird übersetzt');
 * });
 *
 * @param domain
 * @returns Promise
 */
function localize(domain) {
    /**
     * Die Funktion wird an die resolve Funktion des Promise übergeben. Damit können die Texte dann übersetzt werden.
     * @param text
     * @returns {*}
     */
    var getText = function getText(text) {
        let domain_lowercase = domain.toLowerCase();
        let text_lowercase = text.toLowerCase();

        let translated = sessionStorage.getItem('localization_strings.'+domain_lowercase+'.'+text_lowercase);
        if (translated) {
            return translated;
        }

        // no return reached? The translation is not available yet, return the same text.
        console.log('Keine Übersetzung für "' + text + '" in Domäne "' + domain + '" gefunden');
        $.ajax({
            type: 'POST',
            global: false,
            dataType: 'json',
            url: 'evewa4ajax.php',
            data: {
                file: 'localization',
                action: 'add_js_locale',
                domain: domain_lowercase,
                text: text
            }
        });
        return text;
    };

    /**
     * Falls die Übersetzungen nicht geladen werden konnten wird diese Funktion, statt der getText-Funktion,
     * an die resolve-Funktion übergeben. Texte werden dann nicht übersetzt.
     * @param text
     * @returns {*}
     */
    var echo = function echo(text) {
        return text;
    };

    return new Promise(function (resolve) {
        if (globalThis.EVEWA_MULTILANG === false) {
            resolve(echo);
        }

        if (sessionStorage.getItem('localization_domains.'+domain.toLowerCase())) {
            // console.log('Übersetzungen für "' + domain + '" bereits geladen');
            resolve(getText);
        } else {
            //console.log('Lade Übersetzungen für "' + domain + '"');
            $.ajax({
                type: 'POST',
                global: false,
                dataType: 'json',
                url: 'evewa4ajax.php',
                data: {
                    file: 'localization',
                    action: 'get_js_locale',
                    domain: domain
                }
            }).done(function (response) {
                if (response.status === 'success') {
                    sessionStorage.setItem('localization_domains.'+domain.toLowerCase(), '1');
                    for(let key in response.data) {
                        const text_lowercase = key.toLowerCase();
                        sessionStorage.setItem('localization_strings.'+domain.toLowerCase()+'.'+key, response.data[text_lowercase]);
                    }
                    resolve(getText);
                } else {
                    console.log('Fehler beim Laden der lokalisierten Texte');
                    resolve(echo);
                }
            }).fail(function () {
                console.log('Fehler beim Laden der lokalisierten Texte');
                resolve(echo);
            });
        }
    });
}

export { localize };